import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setUploadError(null);
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = async () => {
        if (!selectedFile) {
            alert('Por favor, selecione um arquivo primeiro!');
            return;
        }

        try {

            const userString = localStorage.getItem("authUser");

            if (userString) {
                const userObject = JSON.parse(userString);
            
                setIsUploading(true);
                
                const base64 = await fileToBase64(selectedFile);
                const payload = { 
                    "userEmail": userObject.email,
                    base64Document: base64 };

                const response = await axios.post('/api/v1/upload', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
                    }
                });
        
                console.log('Upload bem-sucedido:', response.data);
                alert('Upload bem-sucedido!');
            }
        } catch (error) {
            console.error('Error:', error);
            setUploadError('Falha no upload.');
        } finally {
            setIsUploading(false);
            setSelectedFile(null);
        }
    }

    return (
        <div>
            <input style={{ width: '200px', height: '35px', marginRight: '10px', marginTop: '10px' }} type="file" onChange={handleFileChange} />
            <button 
                type="button" 
                className="btn btn-primary btn-sm" 
                onClick={handleSubmit}
                disabled={isUploading}
            >
                {isUploading ? 'Uploading...' : 'Upload File'}
            </button>
            {uploadError && <p className="error">{uploadError}</p>}
        </div>

    );
}

export default FileUpload;
