import React, { useState, useEffect } from 'react';

const DataTable = (props) => {
    const [dados, setDados] = useState([]);
    const [filtroStatus, setFiltroStatus] = useState('');
    const [filtroDataSimulacao, setFiltroDataSimulacao] = useState('');
    const [ordenacao, setOrdenacao] = useState('asc'); // 'asc' ou 'desc'
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPorPagina] = useState(10);

    const fetchDemoData = async () => {
        const userString = localStorage.getItem("authUser");

        if (userString) {
            const userObject = JSON.parse(userString);

            try {
                const response = await fetch('/api/v1/list', {
                    method: 'GET',
                    headers: {
                        'userEmail': userObject.email
                    },
                });
                const data = await response.json();
                setDados(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        }
    }

    useEffect(() => {
        fetchDemoData();
    }, []);

    const dadosFiltrados = dados
        .filter(item => filtroStatus === '' || item.status === filtroStatus)
        .filter(item => filtroDataSimulacao === '' || item.date === filtroDataSimulacao)
        .sort((a, b) => {
            if (ordenacao === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

    const dadosPaginados = dadosFiltrados.slice(
        (paginaAtual - 1) * itensPorPagina, 
        paginaAtual * itensPorPagina
    );

    const paginar = (numeroDaPagina) => {
        setPaginaAtual(numeroDaPagina);
    }

    const numeroDePaginas = Math.ceil(dados.length / itensPorPagina);
        const MAX_PAGINAS_VISIVEIS = 10;
        const inicioIntervalo = Math.floor((paginaAtual - 1) / MAX_PAGINAS_VISIVEIS) * MAX_PAGINAS_VISIVEIS;
    
        const renderNumerosPagina = () => {
            const paginas = [];
    
            const inicio = Math.max(1, inicioIntervalo);
            const fim = Math.min(inicio + MAX_PAGINAS_VISIVEIS, numeroDePaginas);
    
            if (inicio > 1) {
                paginas.push(
                    <button className="btn" key="retroceder" onClick={() => paginar(inicio - 1)}>Anterior</button>
                );
            }
    
            for (let i = inicio; i < fim; i++) {
                paginas.push(
                    <button
                        className={`btn ${paginaAtual === i ? 'active' : ''}`}
                        key={i}
                        onClick={() => paginar(i)}
                    >
                        {i}
                    </button>
                );
            }
    
            if (fim < numeroDePaginas) {
                paginas.push(
                    <button className="btn" key="avançar" onClick={() => paginar(fim)}>Próximo</button>
                );
            }
    
            return paginas;
        };

        const [recarregarAutomatico, setRecarregarAutomatico] = useState(
            JSON.parse(localStorage.getItem('recarregarAutomatico')) || false
        );
    
        useEffect(() => {
            localStorage.setItem('recarregarAutomatico', recarregarAutomatico);
        }, [recarregarAutomatico]);
    
        useEffect(() => {
            let intervalo;
    
            if (recarregarAutomatico) {
                intervalo = setInterval(() => {
                    window.location.reload();
                }, 20000);
            }
    
            return () => {
                if (intervalo) {
                    clearInterval(intervalo);
                }
            };
        }, [recarregarAutomatico]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                    <select style={{ width: '200px', height: '35px', marginRight: '10px', marginTop: '10px' }}  onChange={e => setFiltroStatus(e.target.value)} value={filtroStatus}>
                        <option value="">Todos os Status</option>
                        <option value="Aprovado">Aprovado</option>
                        <option value="Reprovado">Reprovado</option>
                    </select>
                <button className="btn btn-primary" style={{ width: '150px', height: '35px', marginRight: '10px', marginTop: '10px' }} onClick={() => setOrdenacao(ordenacao === 'asc' ? 'desc' : 'asc')}>
                    Ordenar {ordenacao === 'asc' ? 'Desc' : 'Asc'}
                </button>
                <button className="btn btn-warning" style={{ width: '150px', height: '35px', marginRight: '10px', marginTop: '10px' }} onClick={() => setRecarregarAutomatico(!recarregarAutomatico)}>
                    {recarregarAutomatico ? 'Desativar Reload' : 'Ativar Reload'}
                </button>
            </div>
            <hr></hr>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">NOME</th>
                        <th scope="col">CPF</th>
                        <th scope="col">Data Nascimento</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Status</th>
                        <th scope="col">Parceiro</th>
                        <th scope="col">Data Simulação</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {dadosPaginados.map((item, index) => (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.document}</td>
                            <td>{item.birthDate}</td>
                            <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.maxValue)}</td>
                            <td>{item.status}</td>
                            <td>Dr.Cash</td>
                            <td>{item.date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br></br>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                {renderNumerosPagina()}
            </div>
        </div>
    );
}

export default DataTable;