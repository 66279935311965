const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    // {
    //     label: "Dashboard",
    //     icon: "mdi mdi-home-variant-outline",
    //     url: "/dashboard",
    //     badgeValue: ""
    // },
    {
        label: "Security",
        icon: "ri-secure-payment-fill",
        isHasArrow: true,
        url: "/security",
        issubMenubadge: true,
        bgcolor: "bg-primary"
    }
    // {
    //     label: "Upload",
    //     icon: "ri-upload-2-fill",
    //     isHasArrow: true,
    //     url: "/upload",
    // }
    // ,
    // {
    //     label: "Arquivo example",
    //     icon: "ri-question-answer-fill",
    //     isHasArrow: true,
    //     url: "https://docs.google.com/spreadsheets/d/1WjMzBGhvrDLDCU5FTqDeqrPmKrIDuYzj/edit?usp=sharing&ouid=115869771722656494039&rtpof=true&sd=true",
    //     EventTarget: "_blank",
    // }
]
export default SidebarData;